import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function Services() {

return (
    <div className="base-page">
    <Header />
    <div className="sections">
    <h2 className="sub-title">Services Offered</h2>
    <ul className="u-list service-list">
        <li className="list-item service-item">
        <i className="fa-solid fa-tree"></i>
        <p className="green-bold list-title">Tree Removal</p><br />
        We approach a tree removal with safety at the forefront.
        We integrate communication control and experience to achieve this goal,
        while maintaining respect for the client's property.  We have never had
        a workmans comp claim in 20 years!
        </li>
        <li className="list-item service-item">
        <i className="fa-solid fa-tree"></i>
        <p className="green-bold list-title">Tree Trimming</p><br />
        We abide by the 1/3 rule when trimming.  We focus on lifting the canopy,
        creating balance and symmetry, redirecting limbs away from structures,
        eliminating redundant and codominant stems, and removing obvious dead wood.
        </li>
        <li className="list-item service-item">
        <i className="fa-solid fa-tree"></i>
        <p className="green-bold list-title">Stump Grinding</p><br />
        We grind 4-6 inches below the ground if planting grass is the goal.
        <br />We grind 14 inches if replanting a tree or bush.
        <br />We grind all visible roots.
        <br />We use a containment device for stump grindings.
        </li>
        <li className="list-item service-item">
        <i className="fa-solid fa-tree"></i>
        <p className="green-bold list-title">Lot Clearing</p><br />
        Although we are a smaller company, we have networked with half a dozen other
        tree services in the past to create a sizable force for larger jobs.
        </li>
    </ul>
    </div>
    <Footer />
    </div>

)

}