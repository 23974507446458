import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

export default function Navbar() {

    return (
        <nav className="navbar navbar-expand">
            <div className="container-fluid">
                <div className="nav-box">
                <h1 className="page-title">Grand Chute Tree Service</h1>
                    <div className="collapse navbar-collapse">
                    {/* <a className="navbar-brand">GCTS</a> */}
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/services">Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/equipment">Equipment</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Contact Us</Link>
                            </li>
                        </ul>
                        <p className="nav-phone">920-419-1003</p>
                    </div>
                </div>
            </div>
        </nav>

    )

}