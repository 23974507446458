import React from "react";
import Header from "./Header";
import Footer from "./Footer";


export default function Contact() {

return (
    <div className="base-page">
    <Header />
    <div className="sections">
    <h2 className="sub-title">Contact</h2>
    <p className="green-bold c-name">Daniel D Boettcher</p><br />
    <p className="green-bold">920-419-1003</p>
    <h2 className="sub-title locations-header">Locations</h2>
    <table className="locations-table">
        <tr>
            <td className="green-bold td">Appleton</td>
            <td className="green-bold td">Neenah</td>
            <td className="green-bold td">Greenville</td>
        </tr>
        <tr>
            <td className="green-bold td">Grand Chute</td>
            <td className="green-bold td">Menasha</td>
            <td className="green-bold td">Hortonville</td>
        </tr>
        <tr>
            <td className="green-bold td">Little Chute</td>
            <td className="green-bold td">Kimberly</td>   
            <td className="green-bold td">Sherwood</td>
        </tr>
    </table> 
    </div>
    <Footer />
    </div>

)

}