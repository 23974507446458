import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/styles.css"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
import Services from "./Services";
import Contact from "./Contact";
import Equipment from "./Equipment";

export default function App() {

return (
    <Router>
        <Routes>
            <Route path="/" element={<Layout />} />
            <Route index element={<Home />} />
            <Route path="services" element={<Services />} />
            <Route path="equipment" element={<Equipment />} />
            <Route path="contact" element={<Contact />} />
        </Routes>
    </Router>

)

}