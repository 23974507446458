import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function Equipment() {

return (
    <div className="base-page">
    <Header />
    <div className="sections">
    <h2 className="sub-title">Equipment</h2>
    <ul className="u-list equip-list">
        <li className="list-item">
        <i className="fa-solid fa-gear"></i>
        <p className="green-bold list-title">Chipper</p><br />
        9 inch towable.
        </li>
        <li className="list-item">
        <i className="fa-solid fa-gear"></i>
        <p className="green-bold list-title">Self-propelled Stump Grinder</p><br />
        Able to get through a 36 inch gate.
        </li>
        <li className="list-item">
        <i className="fa-solid fa-truck"></i>
        <p className="green-bold list-title">Dump Truck</p><br />
        Light weight item box dump.
        </li>
        <li className="list-item">
        <i className="fa-solid fa-truck"></i>
        <p className="green-bold list-title">Towable Lift</p><br />
        60 foot low impact, articulating.
        </li>
        <li className="list-item">
        <i className="fa-solid fa-tractor"></i>
        <p className="green-bold list-title">Tractor</p><br />
        Turf tires to eliminate lawn damage.  Grapple for quick bush removal.
        </li>
    </ul>
    </div>
    <Footer />
    </div>

)

}