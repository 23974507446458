import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import gctsTrainee from "../images/gcts-trainee.jpg";
import gctsLift from "../images/gcts-lift.jpg";
import gctsSun from "../images/gcts-1.jpg";


export default function Home() {

    return (
        <div className="base-page">
            <Header />

            <div>
            <img className="images-home" src={gctsTrainee} />
            <img className="images-home" src={gctsLift} />
            <img className="images-home" src={gctsSun} />
            </div>

            {/* Why Choose */}

                <div className="container sections">
                    <h2 className="sub-title">Why choose Grand Chute Tree Service?</h2>
                    
                    <p className="why-intro">Daniel Boettcher provides professional tree 
                services in Wisconsin working with businesses and homeowners
                in Appleton, Grand Chute, Neenah, Menasha, and surrounding 
                communities in Fox Valley. Dan does tree trimming and pruning, 
                tree removal, and stump  grinding. Dan knows that tree care 
                with proactive measures protects your investment in your home 
                or business. Dan provides competitive quotes on tree stump 
                removal if you have already had your trees removed. Dan is 
                insured and with 23 years of experience can help you make 
                those decisions that may help you down the line.</p>
            
                    <ul className="u-list why-list">
                        <li className="list-item">
                        <i className="fa-solid fa-circle-check"></i>
                        Owner will be present throughout the whole operation.
                        </li>
                        <li className="list-item">
                        <i className="fa-solid fa-circle-check"></i>
                            We live and work by the Golden Rule.
                        </li>
                        <li className="list-item">
                        <i className="fa-solid fa-circle-check"></i>
                            23 years of experience.
                        </li>
                        <li className="list-item">
                        <i className="fa-solid fa-circle-check"></i>
                        10+ years of relationships with many landscapers and property management companies.
                        </li>
                        <li className="list-item">
                        <i className="fa-solid fa-circle-check"></i>
                            Fully insured, we carry property damage and workman's comp.
                        </li>
                        <li className="list-item">
                        <i className="fa-solid fa-circle-check"></i>
                            Great communication.
                        </li>
                    </ul>
                    </div>

            {/* Discount */}

                    <div className="discount-bg">
                    <div className="sections">
                        <h3 className="sub-title">Discounts</h3>
                        <p>We offer discounts to veterans and community service providers, so please let us know!</p>
                        <p className="past-work green-bold">In the past we have worked for:</p>
                        <ul className="u-list">
                            <li className="list-item">
                            <i className="fa-solid fa-building"></i>
                            Habitat for Humanity
                            </li>
                            <li className="list-item">
                            <i className="fa-solid fa-building"></i>
                            Rebuilding Together
                            </li>
                            <li className="list-item">
                            <i className="fa-solid fa-building"></i>
                            Appleton Housing Authority
                            </li>
                            <li className="list-item">
                            <i className="fa-solid fa-church"></i>
                            Salvation Army
                            </li>
                            <li className="list-item">
                            <i className="fa-solid fa-church"></i>
                            And many churches
                            </li>
                        </ul>
                    </div>
                    </div>

            {/* Special Interest */}
            
                    <div className="sections special">
                        <h3 className="sub-title special-header">Of Special Interest</h3>
                        <p className="special-details">The owner has trained many climbers throughout his career, many of which have now successfully started their own businesses in the Fox Valley area.</p>
                        <p className="special-details">FEEL FREE TO ASK FOR A LIST OF REFERENCES IN YOUR AREA!</p>
                        <p className="special-details green-bold">920-419-1003</p>
                    </div>   
                

            <Footer />
        </div>

    )

}